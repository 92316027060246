<template>
  <div class="flex-row-fluid ml-lg-12">
    <div class="card card-custom card-stretch">
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">头像管理</h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">变更自己的头像</span>
        </div>
      </div>
      <div class="container" style="max-width: 70%">
        <form class="form">
          <div class="card-body">
            <div class="form-group row align-items-center">
              <div class="col-lg-12 col-xl-9">
                <div role="alert" v-if="errors && errors.length > 0" v-bind:class="{ show: errors.length }" class="alert fade alert-danger">
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
                <div role="alert" v-if="msgs && msgs.length > 0" v-bind:class="{ show: msgs.length }" class="alert fade alert-success">
                  <div class="alert-text" v-for="(msg, i) in msgs" :key="i">
                    {{ msg }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row border-bottom pb-3 align-items-center">
              <label class="col-xl-3 col-form-label">当前头像</label>
              <div class="col-xl-6">
                <span class="symbol symbol-70 symbol-light-success" v-if="user">
                  <img v-if="user.downloadUrl" alt="Pic" :src="user.downloadUrl" />
                  <span v-else-if="user.empChar" class="symbol-label font-size-h5 font-weight-bold">
                    {{user.empChar}}
                  </span>
                </span>
              </div>
            </div>
            <div class="form-group row border-bottom pb-3 align-items-center">
              <label class="col-xl-3 col-form-label">头像选择</label>
              <div class="col-xl-9">
                <div class="row">
                  <div class="col-xl-3 pt-3 pb-3 border-bottom">
                    <img class="rounded-circle" alt="Pic" src="media/headDefaultImg/default_beauty.jpg" style="width: 5rem;" v-on:click="selDefaultImg(1)"/>
                  </div>
                  <div class="col-xl-3 pt-3 pb-3 border-bottom">
                    <img class="rounded-circle" alt="Pic" src="media/headDefaultImg/default_colleagues.jpg" style="width: 5rem;" v-on:click="selDefaultImg(2)"/>
                  </div>
                  <div class="col-xl-3 pt-3 pb-3 border-bottom">
                    <img class="rounded-circle" alt="Pic" src="media/headDefaultImg/default_family.jpg" style="width: 5rem;" v-on:click="selDefaultImg(3)"/>
                  </div>
                  <div class="col-xl-3 pt-3 pb-3 border-bottom">
                    <img class="rounded-circle" alt="Pic" src="media/headDefaultImg/default_friend.jpg" style="width: 5rem;" v-on:click="selDefaultImg(4)"/>
                  </div>
                  <div class="col-xl-3 pt-3 pb-3 border-bottom">
                    <img class="rounded-circle" alt="Pic" src="media/headDefaultImg/default_handsome.jpg" style="width: 5rem;" v-on:click="selDefaultImg(5)"/>
                  </div>
                  <div class="col-xl-3 pt-3 pb-3 border-bottom">
                    <img class="rounded-circle" alt="Pic" src="media/headDefaultImg/default_trade.jpg" style="width: 5rem;" v-on:click="selDefaultImg(6)"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row border-bottom pb-3 align-items-center">
              <label class="col-xl-3 col-form-label">自定义上传头像</label>
              <div class="col-xl-6">
                <button class="btn btn-sm btn-outline-primary">电脑上传</button>
                <a href="javascript:;" class="fileUp">
                  <input id="file" type="file" v-on:change="uploadFile">
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/core/services/store";
import {UPDATE_USER} from "@/core/services/store/auth.module";
export default {
  computed: {
    user: {
      get() {return this.$store.getters.currentUser;}
    }
  },
  data() {
    return {
      errors: [],
      msgs: [],
    };
  },
  methods: {
    selDefaultImg: function(item) {
      this.errors = [];
      this.msgs = [];
      if (this.user && this.user.recordId) {
        this.user.defaultImg = item;
        this.$axios.fetchPost('hr/user/selDefaultImg', this.user).then((data) => {
          if (data && data.data) {
            if (data.data === "noDb") {
              this.errors.push("企业平台错误");
            } else if (data.data === 'success') {
              this.msgs.push("更换成功!");
              store.dispatch(UPDATE_USER);
            } else {
              this.errors.push("更换失败!");
            }
          } else {
            this.errors.push("系统错误!");
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.errors.push("登录已过期，请刷新页面!");
      }
    },
    uploadFile: function(file) {
      this.errors = [];
      this.msgs = [];
      if (this.user && this.user.recordId) {
        const f = file.target.files[0];
        const type = ['image/jpg', 'image/jpeg', 'image/png'];
        if (f && type.indexOf(f.type) >= 0) {
          // 通过form表单进行提交
          const param = new FormData();
          param.append('file', f);
          param.append('id', this.user.recordId);
          this.$axios.formPost('hr/user/uploadFile', param).then((data) => {
            if (data.data) {
              if (data.data === "noDb") {
                this.errors.push("企业平台错误");
              } else if (data.data === 'have') {
                this.errors.push("该文件名已经上传!");
              } else if (data.data === 'false') {
                this.errors.push("上传失败，请刷新重试!");
              } else if (data.data === 'success') {
                this.msgs.push("上传成功!");
                store.dispatch(UPDATE_USER);
              }
            } else {
              this.errors.push("系统错误!");
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          this.errors.push("只能上传jpg/jpeg/png后缀名的文件!");
        }
      } else {
        this.errors.push("登录已过期，请刷新页面!");
      }
    }
  }
}
</script>